<template>
    <span :class="['spinner', size]"><span></span><span></span><span></span><span></span></span>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: 'small'
        }
    }
};
</script>
