<template>
  <div class="wallet" v-if="state.stacksLoggedIn">
    <span class="wallet-address">{{ stacksAddress }}</span>
    <a @click="stacksLogout" class="button border-white log-out">Log Out</a>
  </div>
  <div class="wallet" v-else>
    <button @click="stacksLogin" class="button yellow">Connect</button>
  </div>
</template>

<script>
import { AppConfig, showConnect, UserSession } from '@stacks/connect';
import { getDefaultStacksAddress, isDev, state } from '@/main';
import { ref } from 'vue';
import { getShortStacksAddress } from '../helpers/getShortStacksAddress';

export default {
  components: {},
  async setup() {
    const appConfig = new AppConfig(["store_write", "publish_data"]);
    const userSession = new UserSession({appConfig});
    const stacksAddress = ref('');

    const updateStacksData = () => {
      state.userData = userSession.loadUserData() || null;
      state.stacksUser = isDev ? state.userData.profile.stxAddress.testnet : state.userData.profile.stxAddress.mainnet;
      state.stacksLoggedIn = true;
      stacksAddress.value = getShortStacksAddress(state.stacksUser);
    };

    if (userSession.isUserSignedIn()) {
      updateStacksData();
    }

    const stacksLogin = () => {
      if (userSession.isSignInPending()) {
        userSession.handlePendingSignIn().then(() => {
          updateStacksData();
        });

        return;
      }

      showConnect({
        appDetails: {
          name: "Satoshibles Events",
          icon: state.icon,
        },
        redirectTo: "/",
        onCancel: () => {
          console.log('login cancelled');
        },
        onFinish: () => {
          updateStacksData();
        },
        userSession: userSession,
      });
    };

    const stacksLogout = (e) => {
      e.stopPropagation();

      userSession.signUserOut();

      state.userData = null;
      state.stacksUser = getDefaultStacksAddress();
      state.stacksLoggedIn = false;
    };

    return {
      state,
      stacksAddress,
      stacksLogin,
      stacksLogout,
    };
  }
};
</script>