export function getShortStacksAddress(address) {
    if (!address) {
        return null;
    }

    let start = address.substring(0, 5),
        end = address.slice(-5);

    return (start + '...' + end).toUpperCase();
}
