<template>
  <div class="page">
    <div id="header">
      <div class="header-content page-width">
        <div class="logo">
          <img src="../images/logo.png" alt="Bitcoin On The Beach 2022">
        </div>
        <div>
          <suspense>
            <template #default>
              <Wallet />
            </template>
            <template #fallback>
              <div class="loader">
                <Spinner />
              </div>
            </template>
          </suspense>
        </div>
      </div>
    </div>
    <div class="content page-width">
     <div class="box">
       <div v-if="showLoader">
         <Spinner size="large" />
       </div>
       <div v-else class="signup">
         <h2>Claim your free exclusive NFT!</h2>
         <img src="../images/nft.jpg" alt="NFT Preview" class="preview">
         <p>Thank you for visiting Bitcoin On The Beach, hosted by Satoshibles. Make sure to join our discord here: <strong><a href="https://join.satoshibles.com" target="_blank">join.satoshibles.com</a></strong></p>
         <p>You can claim your free NFT here:</p>
         <p><a href="https://create.stxnft.com/collection/bitcoin-on-the-beach" class="button large" target="_blank">Claim Free NFT</a></p>
       </div>
     </div>
    </div>
  </div>
</template>

<script>
import Wallet from '@/js/components/Wallet';
import Spinner from '@/js/components/Spinner';
import { post } from '@/js/helpers/post';
import { state } from '@/main';
import { ref } from 'vue';
import { sleep } from '@/js/helpers/sleep';

export default {
  components: {
    Wallet,
    Spinner
  },
  setup() {
    const showLoader = ref(false);
    const showResponse = ref(false);
    const responseType = ref('');
    const email = ref('');
    const discord = ref('');

    const submit = async () => {
      showLoader.value = true;

      await sleep(1000);

      const response = await post('https://satoshibles.com/api/mint-subscribe', {
        wallet_address: state.stacksUser,
        discord_username: discord.value,
        email: email.value
      });

      showLoader.value = false;
      showResponse.value = true;

      if (response && response.message && response.message === 'Server Error') {
        responseType.value = 'already-submitted';
      } else {
        responseType.value = 'thank-you';
      }
    };

    const goBack = () => {
      responseType.value = '';
      showLoader.value = false;
      showResponse.value = false;
    }

    return {
      submit,
      goBack,
      state,
      responseType,
      showLoader,
      discord,
      showResponse,
      email
    };
  }
};
</script>